import { useTranslation } from '../../hooks/useTranslation'

export const useYesNoQuizScore = (content) => {
  const numAs = Object.values(content).filter((val) => val === 'a').length
  const score = Math.round((numAs / Object.keys(content).length) * 100)
  if (numAs <= 8) {
    return [1, numAs]
  } else if (numAs <= 14) {
    return [2, numAs]
  } else {
    return [3, score]
  }
}

export const useMultipleChoiceQuizScore = (content, questions) => {
  let tempNumCorrect = 0
  const contentKeys = Object.keys(content)
  contentKeys.forEach((key) => {
    const answerChoice = content[key]
    if (questions[key].options[answerChoice].feedback_key === 'yes_response') {
      tempNumCorrect += 1
    }
  })
  const numCorrect = tempNumCorrect
  const score = Math.round((numCorrect / contentKeys.length) * 100)
  const link1 = 'Building a Personal Brand in the Digital Age'
  const link2 = 'Personal Branding in the Digital Age '

  if (numCorrect > 7) {
    return [1, score, link1]
  } else if (numCorrect > 4) {
    return [2, score, link1]
  } else if (numCorrect > 2) {
    return [3, score, link2]
  } else {
    return [4, score, link1]
  }
}

export const useJobSearchStrategyKnowledgeScore = (
  trackContent,
  formattedQuizName,
) => {
  const { t } = useTranslation(formattedQuizName)

  const calculateScore = (content, questions) => {
    let tempNumCorrect = 0
    const contentKeys = Object.keys(content)

    contentKeys.forEach((key) => {
      const answerChoice = content[key]
      if (
        questions[key].options[answerChoice].feedback_key === 'yes_response'
      ) {
        tempNumCorrect += 1
      }
    })

    const numCorrect = tempNumCorrect

    return numCorrect
  }

  const getScoreSectionAndProficiency = (numCorrect) => {
    if (numCorrect === 15) return { section: 1, proficiency: 'expert' }
    if (numCorrect >= 12) return { section: 2, proficiency: 'advanced' }
    if (numCorrect >= 7) return { section: 3, proficiency: 'developing' }
    if (numCorrect >= 6) return { section: 4, proficiency: 'novice' }
    return { section: 4, proficiency: 'novice' }
  }

  const score = calculateScore(trackContent, t('questions'))
  const { section, proficiency } = getScoreSectionAndProficiency(score)

  return { score, section, proficiency, t }
}
