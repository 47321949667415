import * as React from 'react'

import { TrainingSurveyForm } from './TrainingSurveyForm'

export default function TrainingSurvey({
  programs,
  instructors,
  questions,
  choices,
  thank_you,
}) {
  const [error, setError] = React.useState(false)
  const [thankYou, setThankYou] = React.useState(thank_you)

  if (error) return <ErrorScreen />

  if (thankYou) return <ThankYouScreen />

  return (
    <div className="container mx-auto flex flex-col p-4 max-sm:max-w-md sm:p-16 lg:max-w-3xl">
      <div className="space-y-4">
        <h1 className="text-2xl font-extrabold md:text-4xl">
          Evaluate Your Training
        </h1>
        <p className="text-sm text-gray-500 md:text-xl dark:text-gray-400">
          Thank you for attending our training course on Zoom. Your feedback is
          invaluable to us as we strive to continually improve our courses.
        </p>
        <p className="text-sm text-gray-500 md:text-xl dark:text-gray-400">
          Please take a few minutes to complete this evaluation form. Your
          honest responses will help us understand what worked well and areas
          where we can make improvements.
        </p>
        <TrainingSurveyForm
          programs={programs}
          instructors={instructors}
          questions={questions}
          choices={choices}
          setThankYou={setThankYou}
          setError={setError}
        />
      </div>
    </div>
  )
}

function ThankYouScreen() {
  return (
    <div className="container mx-auto flex flex-col p-4 max-sm:max-w-md sm:p-16 lg:max-w-3xl">
      <div className="space-y-4">
        <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl/none">
          Thank You!
        </h1>
        <p className="text-gray-500 md:text-xl dark:text-gray-400">
          Thank you for taking the time to provide your feedback. Your input is
          crucial to our continuous improvement efforts. We appreciate your
          participation in the course and hope to serve you better in the
          future.
        </p>
      </div>
    </div>
  )
}

function ErrorScreen() {
  return (
    <div className="container mx-auto flex flex-col p-4 max-sm:max-w-md sm:p-16 lg:max-w-3xl">
      <div className="space-y-4">
        <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl/none">
          Oops! Something went wrong.
        </h1>
        <p className="text-gray-500 md:text-xl dark:text-gray-400">
          We&apos;re sorry, but something went wrong. Please contact your
          Program Trainer about this issue.
        </p>
      </div>
    </div>
  )
}
