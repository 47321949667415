import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { api } from '../../util/api'

export const useSurveyForm = ({
  surveyType,
  questions,
  setThankYou,
  setError,
}) => {
  const schema = {
    program_date: z.date({
      required_error: 'Please pick the program date.',
    }),
    program: z.string().min(2, {
      message: 'Please choose a program name.',
    }),
    instructor: z.string().min(2, {
      message: 'Please select your trainers name.',
    }),
  }

  const defaultValues = {
    program: '',
    program_date: '',
    instructor: '',
    participant_first_name: '',
    participant_last_name: '',
    participant_email: '',
  }

  const questionNames = getQuestionNames(questions)

  questionNames.ratingExcellence.forEach((name) => {
    schema[name] = z.number().optional()
    defaultValues[name] = 0
  })

  questionNames.ratingSatisfaction.forEach((name) => {
    schema[name] = z.number().optional()
    defaultValues[name] = 0
  })

  questionNames.openEnded.forEach((name) => {
    schema[name] = z.string().optional()
    defaultValues[name] = ''
  })

  questionNames.additionalComments.forEach((name) => {
    schema[name] = z.string().optional()
    defaultValues[name] = ''
  })

  const form = useForm({
    resolver: zodResolver(z.object(schema)),
    defaultValues,
  })

  async function onSubmit(values) {
    let response
    try {
      response = await api.post(`/visitor_surveys`, {
        survey_type: surveyType,
        survey: {
          program: values.program,
          program_date: new Date().toISOString(),
          instructor: values.instructor,
          participant_first_name: values.participant_first_name,
          participant_last_name: values.participant_last_name,
          participant_email: values.participant_email,
          survey_data: getSurveyData(values, questionNames),
        },
      })
    } catch (error) {
      console.error(error)
      setError(true)
      return
    }
    if (response.status === 201) {
      setThankYou(true)
    } else {
      console.log(response)
      setError(true)
    }
  }

  return { form, onSubmit }
}

const getSurveyData = (values, names) => {
  const surveyData = {
    rating_excellence: {},
    rating_satisfaction: {},
    open_ended: {},
    additional_comments: {},
  }

  names.ratingExcellence.forEach((name) => {
    surveyData.rating_excellence[name] = values[name]
  })

  names.ratingSatisfaction.forEach((name) => {
    surveyData.rating_satisfaction[name] = values[name]
  })

  names.openEnded.forEach((name) => {
    surveyData.open_ended[name] = values[name]
  })

  names.additionalComments.forEach((name) => {
    surveyData.additional_comments[name] = values[name]
  })

  return surveyData
}

function getQuestionNames(questions) {
  return {
    ratingExcellence: questions.rating_excellence.map(
      (question) => question.name,
    ),
    ratingSatisfaction: questions.rating_satisfaction.map(
      (question) => question.name,
    ),
    openEnded: questions.open_ended.map((question) => question.name),
    additionalComments: questions.additional_comments.map(
      (question) => question.name,
    ),
  }
}
