import React, { useState } from 'react'
import Progress from './Progress'
import Avatar from './Avatar'
import { useIsDesktop } from '../../hooks/useMediaQuery'
import { useTranslation } from '../../hooks/useTranslation'
import {
  useMultipleChoiceQuizScore,
  useYesNoQuizScore,
  useJobSearchStrategyKnowledgeScore,
} from '../JobSearchQuiz/useScoreCalculator'
import { PrimaryQuizButton, BackQuizButton } from './Buttons'
import { DownArrow, RightArrow } from './Icons/Arrows'
import Task from './Task'
import { useQuiz } from './QuizProvider'
import { cn } from '../../lib/utils'

const Results = ({ className, children }) => {
  const { setShowResults, quizPartId } = useQuiz()
  const [disabledButton, setDisabledButton] = useState(false)

  const handleNextClick = () => {
    localStorage.removeItem(`${quizPartId}:started`)
    localStorage.removeItem(`${quizPartId}:results`)
    localStorage.removeItem('idx')
    setDisabledButton(true)
    window.location.pathname = '/'
  }
  return (
    <div className={cn('container mt-6 flex flex-col', className)}>
      <Progress completed />
      {children}
      <div className="mt-8 flex gap-4 self-end sm:gap-6">
        <BackQuizButton onClick={() => setShowResults(false)}>
          Back
        </BackQuizButton>
        <div className="flex flex-col items-center gap-2">
          <PrimaryQuizButton
            onClick={handleNextClick}
            disabled={disabledButton}
          >
            My Track
          </PrimaryQuizButton>
        </div>
      </div>
    </div>
  )
}

export default Results

const LinkifiedCopy = ({ copy, link }) => {
  const copySplit = copy.split('[link]')
  const { linkPath, linkText } = link
  const copy1 = copySplit[0]
  const copy2 = copySplit[1]
  return (
    <p>
      {copy1}
      {copy2 && <a href={linkPath}>{linkText}</a>}
      {copy2}
    </p>
  )
}

export const PersonalBrandQuizResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent, quizName } =
    useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()
  const { t } = useTranslation(formattedQuizName)
  const [scoreSection, score, linkText] = useMultipleChoiceQuizScore(
    trackContent,
    t('questions'),
  )

  const header = t(`results.${scoreSection}.header`)
  const copy = t(`results.${scoreSection}.copy`, { score })

  const link = {
    linkPath: '/personal_brand/resources/article/building-personal-brand',
    linkText: linkText,
  }

  return (
    quizName === 'Personal Brand Iq' && (
      <Results>
        <div className="flex pb-6 sm:justify-around">
          <div className="mt-1 flex h-96 flex-col gap-4 sm:w-9/12">
            {scoreSection !== 4 && <h1 className="!text-[23px]">{header}</h1>}
            <h2>{t(`results.score`, { score })}</h2>
            <div className="mt-4">
              {copy.map((copy, idx) => (
                <LinkifiedCopy key={`copy-${idx}`} copy={copy} link={link} />
              ))}
              <p>{t(`results.congrats`)}</p>
            </div>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
      </Results>
    )
  )
}

export const JobSearchQuizResults = ({ tasks }) => {
  const {
    quizName,
    formattedQuizName,
    resultsPageAvatar,
    trackContent,
    numChoices,
  } = useQuiz()
  const avatar = resultsPageAvatar
  const resultsTasks = Object.values(tasks)
  const isDesktop = useIsDesktop()
  const { t } = useTranslation(formattedQuizName)
  const [scoreSection, variable] = useYesNoQuizScore(
    trackContent,
    numChoices,
    t('questions'),
  )

  const header = t(`results.${scoreSection}.header`)
  const copy =
    scoreSection === 2
      ? t(`results.${scoreSection}.copy`, { numAs: variable })
      : t(`results.${scoreSection}.copy`, { score: variable })

  return (
    quizName === 'Job Search' && (
      <Results>
        <div className="flex pb-6 sm:justify-around">
          <div className="mt-1 sm:w-9/12">
            <h1 className="!text-[23px]">{header}</h1>
            <p className="mt-4">{copy}</p>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
        <div className="divide-y divide-solid divide-[#B9B9B9] rounded-[10px] border-[1.5px] border-solid border-[#08467C]">
          <div
            style={{ fontFamily: 'gotham_boldregular' }}
            className="flex flex-col items-center py-4 sm:flex-row sm:pl-16"
          >
            <div className="sm:w-2/5">Remaining areas of preparation</div>
            {!isDesktop && <DownArrow className="py-2" />}
            {isDesktop && <RightArrow className="sm:w-1/5" />}
            <div className="flex flex-col items-center sm:w-2/5">
              <div>Tasks added to My Track</div>
            </div>
          </div>
          {resultsTasks.map((task, idx) => {
            const { task: taskText, links } = task
            return (
              <Task
                key={`task-${idx}`}
                className={`flex flex-col items-center py-4 sm:flex-row sm:pl-16`}
                task={taskText}
                links={links}
                showArrow={isDesktop}
              />
            )
          })}
        </div>
      </Results>
    )
  )
}

export const JobSearchStrategyKnowledgeResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent, quizName } =
    useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()
  const { score, section, proficiency, t } = useJobSearchStrategyKnowledgeScore(
    trackContent,
    formattedQuizName,
  )

  const copy = t(`results.${section}`, { score })

  return (
    quizName === 'Job Search Strategy Knowledge' && (
      <Results>
        <div className="flex pb-6 sm:justify-around">
          <div className="mt-2 flex h-96 flex-col gap-4 sm:w-9/12">
            <h2>{t(`results.score`, { proficiency })}</h2>
            <div className="mt-4">
              <p>{copy}</p>
              <br />
              <p>{t(`results.all`)}</p>
            </div>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
      </Results>
    )
  )
}
