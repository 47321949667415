import * as React from 'react'

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../lib/ui/form'
import { RadioGroup, RadioGroupItem } from '../../lib/ui/radio-group'

export function RatingField({ name, label, choices, control }) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className="">
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <RadioGroup
              onValueChange={field.onChange}
              defaultValue={field.value}
              className="flex flex-col rounded-md ring-offset-background transition-colors md:flex-row"
            >
              {choices.map((choice) => (
                <FormItem
                  className="items-left flex-grow space-x-3 space-y-0"
                  key={choice.value}
                >
                  <FormLabel className="flex cursor-pointer items-center rounded-md border px-3 py-2 text-muted-foreground hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground has-[:checked]:border-primary has-[:checked]:bg-accent has-[:checked]:text-accent-foreground has-[:focus-visible]:outline-none has-[:focus-visible]:ring-2 has-[:focus-visible]:ring-ring has-[:focus-visible]:ring-offset-2 md:justify-center">
                    <FormControl>
                      <RadioGroupItem
                        className="md:sr-only"
                        value={choice.value}
                      />
                    </FormControl>
                    <p className="text-center text-sm font-medium max-md:ml-3">
                      {choice.label}
                    </p>
                  </FormLabel>
                </FormItem>
              ))}
            </RadioGroup>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
