import React, { useState } from 'react'
import AccessibleFormLabel from './AccessibleFormLabel'
import QRCode from 'react-qr-code'
import PasswordInput from './PasswordInput'
import { Box, Input, FormControl, Button, Link } from '@mui/joy'
import { SpinnerWhite } from '../../shared/Spinner'
import { useData } from './DataProvider'
import { useAlert } from './AlertModal'
import useTranslation from '../../shared/TranslationProvider'

const TwoFactorCreate = () => {
  const { isLoading, handleFormResponse, setIsLoading, otpCreateData, getApi } =
    useData()

  const { setAlertState, setAlertData } = useAlert()

  const t = useTranslation()

  const [authenticationCode, setAuthenticationCode] = useState('')
  const [password, setPassword] = useState('')

  const handleTwoFactor = () => {
    setIsLoading(true)
    asyncHandleTwoFactor()
  }

  const asyncHandleTwoFactor = async () => {
    try {
      const response = await getApi().post('/users/two_factor_settings', {
        two_fa: { code: authenticationCode, password },
      })
      if (!response.data.location) {
        setAlertData(response.data.alert)
        setAlertState('alert')
      }
      handleFormResponse(response)
    } catch (error) {
      setAlertData('Error validating code or password, please try again.')
      setAlertState('alert')
    }
  }
  return (
    <Box
      sx={{
        '--textColor': '#171923',
        color: '#171923',
      }}
      className="two-factor-create"
    >
      <Box>
        <h1>{t('TwoFactorCreate.title')}</h1>
      </Box>
      <Box className="link-container subtext-container">
        <h3>{t('TwoFactorCreate.step1')}</h3>
        {t('TwoFactorCreate.step1_content')}
      </Box>
      <Box className="link-container subtext-container">
        <h3>{t('TwoFactorCreate.step2')}</h3>
        <p>{t('TwoFactorCreate.step2_content')}</p>
        <Box sx={{ margin: '1em 0' }}>
          <QRCode value={otpCreateData.uri} />
        </Box>
        <p>
          {t('TwoFactorCreate.subtext')}:
          <Link href={otpCreateData.uri}>{otpCreateData.secret}</Link>
        </p>
      </Box>
      <Box className="link-container subtext-container">
        <h3>{t('TwoFactorCreate.step3')}</h3>
        {t('TwoFactorCreate.step3_content')}
      </Box>
      <Box className="control-container">
        <FormControl required>
          <AccessibleFormLabel>{t('TwoFactorCreate.code')}</AccessibleFormLabel>
          <Input
            name="verification_code"
            size="lg"
            autoFocus
            value={authenticationCode}
            onChange={(e) => setAuthenticationCode(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleTwoFactor()
            }}
          />
        </FormControl>
      </Box>
      <Box className="control-container">
        <PasswordInput
          password={password}
          setPassword={setPassword}
          onKeyDown={(e) => {
            if (e.key === 'Enter') handleTwoFactor()
          }}
        />
      </Box>
      <Box className="button-container">
        <Button
          className="submit-button btn"
          size="lg"
          fullWidth
          onClick={handleTwoFactor}
          disabled={isLoading}
        >
          {t('TwoFactorCreate.confirm_and_enable')}
          <SpinnerWhite show={isLoading} className="pl-4" />
        </Button>
      </Box>
    </Box>
  )
}

export default TwoFactorCreate
