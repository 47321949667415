import React, { useEffect, useState } from 'react'
import { PrimaryQuizButton, BackQuizButton } from './Buttons'
import LayoutFourOptions from './LayoutFourOptions'
import LayoutTwoOptions from './LayoutTwoOptions'
import LayoutThreeOptions from './LayoutThreeOptions'
import Response from './Response'
import { useIsDesktop, useIsMobile } from '../../hooks/useMediaQuery'
import { useQuiz } from './QuizProvider'
import LayoutTrueFalse from './LayoutTrueFalse'

const Question = ({ questionInfo, numQuestions, idx }) => {
  const {
    saveQuiz,
    setQuizIndex,
    setStarted,
    trackContent,
    bucketPath,
    quizIndex,
  } = useQuiz()
  const [showResponse, setShowResponse] = useState(false)
  const [responseType, setResponseType] = useState(null)
  const [response, setResponse] = useState()
  const options = Object.values(questionInfo.options)
  const question = questionInfo.question
  const image = questionInfo.image
  const isDesktop = useIsDesktop()
  const isMobile = useIsMobile()
  const [disabledButton, setDisabledButton] = useState(true)
  const layout = options.length
  const isTrueFalse = questionInfo.is_true_false

  const updateResponse = (choice) => {
    setResponse(
      choice === 0
        ? questionInfo.feedback.yes_response
        : questionInfo.feedback.no_response,
    )
    setShowResponse(true)
    setResponseType(choice)
    setDisabledButton(false)
  }

  useEffect(() => {
    if (quizIndex === idx) {
      if (trackContent[quizIndex + 1] === 'a') {
        updateResponse(0)
      } else if (trackContent[quizIndex + 1] === 'b') {
        updateResponse(1)
      } else if (trackContent[quizIndex + 1] === 'c') {
        updateResponse(2)
      } else if (trackContent[quizIndex + 1] === 'd') {
        updateResponse(3)
      }
    }
  }, [])

  const handleBackClick = () => {
    if (quizIndex === 0) {
      setStarted(false)
    } else setQuizIndex((prev) => prev - 1)
  }

  const handleNextClick = () => {
    const showResultsPage = quizIndex === numQuestions - 1
    if (showResultsPage) {
      setDisabledButton(true)
      saveQuiz(showResultsPage)
    } else {
      setQuizIndex((prev) => prev + 1)
      setDisabledButton(true)
    }
  }

  useEffect(() => {
    if (isMobile && showResponse) {
      window.scrollTo(0, document.body.scrollHeight)
    }
  }, [showResponse, isMobile])

  const layoutProps = { updateResponse, options, questionInfo }

  const responseClass = layout !== 2 ? 'w-10 md:w-2/5' : 'sm:w-8/12'

  return (
    quizIndex === idx && (
      <div className="flex flex-col sm:mr-24 sm:pl-28">
        <h1 className="text-2xl sm:!my-8">{question}</h1>
        {layout === 2 && isTrueFalse && (
          <LayoutTrueFalse {...layoutProps}>
            {isDesktop && image && (
              <div>
                {image && <img src={bucketPath + image.src} alt={image.alt} />}
              </div>
            )}
          </LayoutTrueFalse>
        )}
        {layout === 2 && !isTrueFalse && (
          <LayoutTwoOptions {...layoutProps}>
            {isDesktop && image && (
              <div>
                {image && <img src={bucketPath + image.src} alt={image.alt} />}
              </div>
            )}
          </LayoutTwoOptions>
        )}
        {layout !== 2 && (
          <LayoutThreeOptions {...layoutProps}>
            {isDesktop && image && (
              <div>
                {image && <img src={bucketPath + image.src} alt={image.alt} />}
              </div>
            )}
          </LayoutThreeOptions>
        )}

        <div className="sm:h-40">
          <Response
            response={response}
            responseType={responseType}
            showResponse={showResponse}
            className={responseClass}
            source={questionInfo.feedback.source}
            showSourceFor={questionInfo.feedback.show_source_for}
            alreadyTransitioned={
              trackContent[quizIndex + 1] === 'a' ||
              trackContent[quizIndex + 1] === 'b' ||
              trackContent[quizIndex + 1] === 'c' ||
              trackContent[quizIndex + 1] === 'd'
            }
          />
        </div>

        <div className="mt-8 flex gap-4 self-end sm:gap-6">
          <BackQuizButton onClick={handleBackClick}>Back</BackQuizButton>
          <div className="flex flex-col items-center gap-[10px]">
            <PrimaryQuizButton
              onClick={handleNextClick}
              disabled={disabledButton}
            >
              Continue
            </PrimaryQuizButton>
            {isDesktop && (
              <div>
                {quizIndex + 1} of {numQuestions}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  )
}

export default Question
